<template>
    <v-container grid-list-xl fluid>
        <h1>Rastreamento de pacote</h1>
        <v-divider class="my-4" />
        <v-layout wrap>
            <v-flex xs12>
                <v-text-field :label="$t('Código pacote')" v-model="ticket" clearable outlined hide-details v-on:keyup.enter="search(1)" append-icon="qr_code_scanner"
                    append-outer-icon="mdi-send" @click:append="openReadQRCode(1)" @click:append-outer="search(1)" />
            </v-flex>

            <v-flex xs12>
                <v-text-field :label="$t('Chave CTE')" v-model="chaveCTE" clearable outlined hide-details v-on:keyup.enter="search(2)" append-icon="qr_code_scanner"
                    append-outer-icon="mdi-send" @click:append="openReadQRCode(2)" @click:append-outer="search(2)" />
            </v-flex>
        </v-layout>

        <v-divider class="my-4" />

        <v-layout wrap v-if="item != null">

            <v-flex xs12>
                <v-chip class="ma-0" outlined color="error" v-if="!item.collectedBy">
                    Ainda não coletado
                </v-chip>
                <v-chip class="ma-0" outlined color="error" v-if="!item.receivedBy">
                    Ainda não recebido
                </v-chip>
            </v-flex>

            <v-flex xs12 v-if="item.collectedDate && $moment.duration($moment().utc().diff($moment(item.collectedDate))).asDays() >= 4">
                <v-alert icon="error" prominent text dense type="error">Pedido já foi coletado a mais de 4 dias e deve ser devolvido e não mais ser feito tentativa de
                    entrega.</v-alert>
            </v-flex>

            <v-flex xs12 v-if="item.client">
                <v-card color="primary" class="mx-auto mt-1">
                    <v-card-text class="white--text">
                        <v-flex xs12 class="text-center">
                            <b>Cliente</b>
                        </v-flex>
                        <v-flex xs12>
                            <b>Nome:</b> {{ item.client.name }}
                        </v-flex>
                        <v-flex xs12>
                            <b>Endereço:</b> {{ item.client.address }}
                        </v-flex>
                    </v-card-text>
                </v-card>
            </v-flex>
            <v-flex xs12>
                <v-card color="primary" class="mx-auto mt-1">
                    <v-card-text class="white--text">
                        <v-flex xs12 class="text-center">
                            <b>Dados do pacote</b>
                        </v-flex>
                        <v-flex xs12>
                            <b>Identificador:</b> {{ item.id }}
                        </v-flex>
                        <v-flex xs12>
                            <b>Chave CTE:</b> {{ item.key }}
                        </v-flex>
                        <v-flex xs12 v-if="item.ticket">
                            <b>Código barras:</b> {{ item.ticket }}
                        </v-flex>
                        <v-flex xs12 v-if="item.nFe">
                            <b>nFe:</b> {{ item.nFe }}
                        </v-flex>
                        <v-flex xs12 v-if="item.order">
                            <b>Código pedido:</b> {{ item.order }}
                        </v-flex>
                        <v-flex xs12 v-if="item.code">
                            <b>Código de terceiro:</b> {{ item.code }}
                        </v-flex>
                        <v-flex xs12 v-if="item.volumeCode">
                            <b>Código volume:</b> {{ item.volumeCode }}
                        </v-flex>
                        <v-flex xs12 v-if="item.idVuupt">
                            <b>ID Vuupt:</b> {{ item.idVuupt }}
                        </v-flex>
                        <v-flex xs12>
                            <b>Criado na fase:</b> {{ item.createMode }}
                        </v-flex>
                        <v-flex xs12 v-if="item.createdBy">
                            <b>Criado por:</b> {{ item.createdBy }}
                        </v-flex>
                        <v-flex xs12>
                            <b>Integração:</b> {{ item.integrationControl }}
                        </v-flex>
                        <v-flex xs12 v-if="item.createdAt">
                            <b>Criado:</b> {{ $moment(item.createdAt).format("DD/MM/YY HH:mm") }}
                        </v-flex>
                        <v-flex xs12 v-if="item.updatedAt">
                            <b>Última atualização:</b> {{ $moment(item.updatedAt).format("DD/MM/YY HH:mm") }}
                        </v-flex>
                        <v-flex xs12 v-if="item.necCreatedAt">
                            <b>Criado(NEC):</b> {{ $moment(item.necCreatedAt).format("DD/MM/YY HH:mm") }}
                        </v-flex>
                        <v-flex xs12 v-if="item.necUpdatedAt">
                            <b>Última atualização(NEC):</b> {{ $moment(item.necUpdatedAt).format("DD/MM/YY HH:mm") }}
                        </v-flex>
                    </v-card-text>
                </v-card>
            </v-flex>
            <v-flex xs12>
                <v-card :color="item.sendCollected ? 'success' : 'error'" class="mx-auto mt-1" v-if="item.collectedBy">
                    <v-card-text class="white--text">
                        <v-flex xs12 class="text-center">
                            <b>Coletado</b>
                        </v-flex>
                        <v-flex xs12>
                            <b>Data:</b> {{ $moment(item.collectedDate).format("DD/MM/YY HH:mm") }}
                        </v-flex>
                        <v-flex xs12>
                            <b>Coletado por:</b> {{ item.collectedBy }}
                        </v-flex>
                        <v-flex xs12>
                            <b>Romaneio:</b> {{ item.checklistCollect }}
                        </v-flex>
                        <v-flex xs12>
                            <b>Enviado (NEC):</b> {{ item.sendCollected ? "Sim" : "Não" }}
                        </v-flex>
                        <v-flex xs12>
                            <v-btn @click="sendNEC('collect', item.id, item.key)" :color="item.sendCollected ? 'white' : 'success'" width="100%" small>
                                <v-icon left>sync</v-icon>Forçar envio NEC
                            </v-btn>
                        </v-flex>
                    </v-card-text>
                </v-card>
            </v-flex>
            <v-flex xs12>
                <v-card :color="item.sendReceived ? 'success' : 'error'" class="mx-auto mt-1" v-if="item.receivedBy">
                    <v-card-text class="white--text">
                        <v-flex xs12 class="text-center">
                            <b>Recebido</b>
                        </v-flex>
                        <v-flex xs12>
                            <b>Data:</b> {{ $moment(item.receivedDate).format("DD/MM/YY HH:mm") }}
                        </v-flex>
                        <v-flex xs12>
                            <b>Recebido por:</b> {{ item.receivedBy }}
                        </v-flex>
                        <v-flex xs12>
                            <b>Romaneio:</b> {{ item.checklistReceive }}
                        </v-flex>
                        <v-flex xs12 v-if="item.driverCollectPack">
                            <b>Motorista:</b> {{ item.driverCollectPack }}
                        </v-flex>
                        <v-flex xs12 v-if="item.driverCollectPackExternal">
                            <b>Motorista tercerizado:</b> {{ item.driverCollectPackExternal }}
                        </v-flex>
                        <v-flex xs12>
                            <b>Enviado (NEC):</b> {{ item.sendReceived ? "Sim" : "Não" }}
                        </v-flex>
                        <v-flex xs12>
                            <v-btn @click="sendNEC('receive', item.id, item.key)" :color="item.sendReceived ? 'white' : 'success'" width="100%" small>
                                <v-icon left>sync</v-icon>Forçar envio NEC
                            </v-btn>
                        </v-flex>
                    </v-card-text>
                </v-card>
            </v-flex>
            <v-flex xs12>
                <v-card :color="returnItem.sendDevolution ? 'success' : 'error'" class="mx-auto mt-1" v-for="(returnItem, index) in item.returnList" :key="index">
                    <v-card-text class="white--text">
                        <v-flex xs12 class="text-center">
                            <b>Devolução: {{ index + 1 }} / {{ item.returnList.length }}</b>
                        </v-flex>
                        <v-flex xs12>
                            <b>Data:</b> {{ $moment(returnItem.date).format("DD/MM/YY HH:mm") }}
                        </v-flex>
                        <v-flex xs12>
                            <b>Recebido por:</b> {{ returnItem.createdBy }}
                        </v-flex>
                        <v-flex xs12>
                            <b>Romaneio:</b> {{ returnItem.checklist }}
                        </v-flex>
                        <v-flex xs12 v-if="returnItem.driver">
                            <b>Motorista:</b> {{ returnItem.driver }}
                        </v-flex>
                        <v-flex xs12 v-if="returnItem.driverExternal">
                            <b>Motorista tercerizado:</b> {{ returnItem.driverExternal }}
                        </v-flex>
                        <v-flex xs12>
                            <b>Enviado (devolução):</b> {{ returnItem.sendDevolution ? "Sim" : "Não" }}
                        </v-flex>
                        <v-flex xs12>
                            <v-btn @click="sendNEC('return', returnItem._id, item.key)" :color="returnItem.sendDevolution ? 'white' : 'success'" width="100%" small>
                                <v-icon left>sync</v-icon>Forçar envio NEC
                            </v-btn>
                        </v-flex>
                    </v-card-text>
                </v-card>
            </v-flex>
        </v-layout>

        <ConfirmModal confirmText="Confirmar" cancelText="Cancelar" ref="confirm" />
        <BarCode :show="showQRCode" @close="showQRCode = false" @onRead="readedQRCode" />
    </v-container>
</template>

<script>
import ConfirmModal from "@/components/core/ConfirmModal";
import BarCode from "@/components/core/BarCode";

export default {
    components: { ConfirmModal, BarCode },

    data() {
        return {
            type: 1,
            showQRCode: false,
            ticket: null,
            chaveCTE: null,
            item: null
        };
    },

    methods: {
        openReadQRCode(type) {
            this.type = type;
            this.showQRCode = true;

        },
        readedQRCode(QRCode) {
            if (this.type == 1) {
                this.ticket = QRCode;
            } else if (this.type == 2) {
                this.chaveCTE = QRCode;
            }
            this.search();
        },
        sendNEC(type, id, key) {
            this.$refs.confirm.open("", "Deseja mesmo forçar o envio? Ação não poderá ser desfeita.").then((confirm) => {
                if (confirm) {
                    this.$http
                        .patch(`tracking/${type}/force/${id}`, { data: { key } })
                        .then(() => {
                            this.$eventHub.$emit("msgSuccess", "Enviado com sucesso.");
                            this.search();
                        })
                        .catch(() => {
                            this.$eventHub.$emit("msgError", "Erro ao enviar notificação para NEC.");
                        });
                }
            });
        },
        async forceGetPack(type) {
            if (this.ticket != null || this.chaveCTE != null) {
                this.$refs.confirm.open("", "Deseja forçar uma busca desse pacote na integração do NEC?").then((confirm) => {
                    if (confirm) {
                        this.$http.post(`integrations`, (type == 1) ? { ticket: this.ticket } : { chaveCTE: this.chaveCTE })
                            .then(() => {
                                this.$eventHub.$emit("msgSuccess", "Pacote integrado com sucesso.");
                            })
                            .catch(() => {
                                this.$eventHub.$emit("msgError", "Erro ao localizar pacote.");
                            });
                    }
                });
            }
        },
        async search(type) {
            this.showQRCode = false;
            this.item = null;

            if (this.ticket == null && this.chaveCTE == null) {
                this.$eventHub.$emit("msgError", "Código inválido.");
            } else {
                await this.$http
                    .post(`tracking/get`, { ticket: this.ticket, chaveCTE: this.chaveCTE })
                    .then((result) => {
                        if (result) {
                            this.item = result;
                        } else {
                            this.$eventHub.$emit("msgError", "Pedido não localizado");
                            this.forceGetPack(type)
                        }
                    })
                    .catch(() => {
                        this.$eventHub.$emit("msgError", "Erro ao buscar.");
                        this.forceGetPack(type)
                    });
            }
            this.timerTick();
        },
    },
};
</script>